function ownerBalance(priceInput) {
  const price = parseFloat(priceInput);
//test
  if (isNaN(price)) return '0.00';

  let priceSuffix = '';
  if (price > 0) {
    priceSuffix = 'CREDIT';
  } else if (price < 0) {
    priceSuffix = 'DEBIT';
  }

  const formattedPrice = Math.abs(price).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  return `${formattedPrice} ${priceSuffix}`.trim();
}

export default ownerBalance
